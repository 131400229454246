(function(){
    var cookies;

    function readCookie(name,c,C,i){
        if(cookies){ return cookies[name]; }

        c = document.cookie.split('; ');
        cookies = {};

        for(i=c.length-1; i>=0; i--){
           C = c[i].split('=');
           cookies[C[0]] = C[1];
        }

        return cookies[name];
    }

    window.readCookie = readCookie; // or expose it however you want
})();

(function(){
    WebFontConfig = {
        google: { families: [ 'Open+Sans:400,300,300italic,400italic:latin' ] }
    };
    (function() {
        var wf = document.createElement('script');
        wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
        wf.type = 'text/javascript';
        wf.async = 'true';
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(wf, s);
    })();
})();

var transitionend = (function (transition) {
    var transEndEventNames = {
        'WebkitTransition': 'webkitTransitionEnd',// Saf 6, Android Browser
        'MozTransition': 'transitionend',      // only for FF < 15
        'transition': 'transitionend'       // IE10, Opera, Chrome, FF 15+, Saf 7+
    };

    return transEndEventNames[transition];
})(Modernizr.prefixed('transition'));

(function ($) {

    var $scroll, $body, $btnNav, $nav, $siteWrapper, clickEvent = 'click tap',
        bodyClick = (navigator.userAgent.match(/ipad|iphone/i)) ? 'touchstart' : clickEvent;

    $(document).ready(function () {
        $scroll = $('html, body');
        $body = $('body');
        $btnNav = $('.js-btn-nav');
        $nav = $('.js-nav-main');
        $siteWrapper = $('.js-site-wrapper');

        if (readCookie('as_cookie') != 1) {
            $body.prepend('<div class="cookie-policy js-cookie-policy"><div class="cookie-policy__inner"><div class="cookie-policy__title">Cookie Policy</div><div class="cookie-policy__content">' + cranwellJames.cookieMsg + '<a href="#" class="cookie-policy__link js-cookie-close">Click here to hide this message</a></div></div></div>');
        }

        $body
            .addClass('is-ready')
            .on(clickEvent, '.js-cookie-close', function(){
                var d = new Date();
                d.setTime( d.getTime() + (365*24*60*60*1000) );
                document.cookie = "as_cookie=1; expires=" + d.toUTCString() + "; path=/";
                $(this).parents('.js-cookie-policy').slideUp(400, function(){
                    $(this).remove();
                });
                return false;
            })
            .on(bodyClick, '.js-btn-nav', function(e){
                $(this).toggleClass('is-active');
                $nav.toggleClass('is-active');
                $siteWrapper.toggleClass('is-nav-active');
                e.stopPropagation();
                return false;
            })
            .on(bodyClick, '.js-nav-main', function(e){
                e.stopPropagation();
            })
            .on(clickEvent, '.js-next-arrow', function(){
                var $next = $(this).parents('.js-home-section').next('.js-home-section');
                $scroll.animate({
                    scrollTop: $next.offset().top
                });
                return false;
            })
            .on(bodyClick, function(){
                if ($nav.hasClass('is-active')) {
                    $btnNav.removeClass('is-active');
                    $nav.removeClass('is-active');
                    $siteWrapper.removeClass('is-nav-active');
                }
            })
            .on(clickEvent, '.wpcf7-not-valid-tip', function(){
                $(this).siblings('input').select().end().remove();
            })
            .on(transitionend, '.js-preload', function(){
                $(this).addClass('is-off');
            });

        if ($body.hasClass('home')) {
            (function () {
                var $hold = $('.js-home-bg-hold'), $slides = $('.js-home-slide'), $current = $slides.first().addClass('is-active');

                var holdImage = function($element) {
                    $element.find('.js-home-bg').one(transitionend, function () {
                        $hold.css('background-image', $current.find('.js-home-bg').css('background-image'));
                    });
                };

                holdImage($current);

                var next = function() {
                    var $next = $current.next('.js-home-slide').length > 0 ? $current.next('.js-home-slide') : $slides.first();

                    $current.removeClass('is-active');
                    $current = $next.addClass('is-active');

                    holdImage($current);
                };

                setInterval(next, 9000);

            })();
        }

    });

})(jQuery);
